import { useEffect, useState } from "react";
import useDocumentTitle, { fetchData } from "utils/functions/service";
import Cookies from "js-cookie";
import { buttonNew } from "utils/functions/themes";
import { UserStatus } from "utils/functions/enums";
import ScrollBar from "utils/scrollbar/ScrollBar";
import Excel from "utils/Excel";
import moment from "moment";
import Spinner from "utils/spinner/Spinner";

function UsersList() {
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("name");
  const [sort, setSort] = useState("asc");
  const [agencies, setAgencies] = useState([]);
  const [users, setUsers] = useState<
    Array<{
      id: string;
      name: string;
      surname: string;
      status: string;
      isPoec: boolean;
      isPoei: boolean;
      isGroupe: boolean;
      isRSA: boolean;
      isFOREM: boolean;
      closeStatus: string;
      actifStatus: string;
      pauseStatus: string;
      createdAt: string;
      agency: { name: string };
      date_start: string;
      date_end: string;
      totalMonitoring: number;
      totalMonitoringTime: number;
      comment: string;
      address: string;
      timeInsideThePath: number;
      realTimeInsideThePath: number;
      monitorings: {
        actionName: string;
        date: string;
        time: number;
        remark: string;
        employeeName: string;
        employeeSurname: string;
      }[];
    }>
  >([]);

  const [filteredUsers, setFilteredUsers] = useState<
    Array<{
      id: string;
      name: string;
      surname: string;
      status: string;
      isPoec: boolean;
      isPoei: boolean;
      isGroupe: boolean;
      isRSA: boolean;
      isFOREM: boolean;
      closeStatus: string;
      actifStatus: string;
      pauseStatus: string;
      createdAt: string;
      agency: { name: string };
      date_start: string;
      date_end: string;
      totalMonitoring: number;
      totalMonitoringTime: number;
      comment: string;
      address: string;
      timeInsideThePath: number;
      realTimeInsideThePath: number;
      monitorings: {
        actionName: string;
        date: string;
        time: number;
        remark: string;
        employeeName: string;
        employeeSurname: string;
      }[];
    }>
  >([]);
  const roles = Cookies.get("roles");
  useDocumentTitle(`Liste des bénéficiaires`);
  useEffect(() => {
    async function fetch() {
      setUsers(await fetchData(`/users`, roles));
      setAgencies(await fetchData("/agencies", roles));
    }
    fetch();
  }, [roles]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (filteredUsers.length > 0) setIsLoading(false);
  }, [filteredUsers]);

  console.log(users);
  const totalActif = filteredUsers.filter(
    (user) => user.status === UserStatus.KOOSHII_ACTIVE
  ).length;

  function filterUsers() {
    let agencyInput = document.getElementById(
      "agency"
    ) as HTMLInputElement | null;
    let statusInput = document.getElementById(
      "status"
    ) as HTMLInputElement | null;
    let usernameInput = document.getElementById(
      "username"
    ) as HTMLInputElement | null;
    let filteredUsers = [...users];

    if (agencyInput && agencyInput.value !== "") {
      filteredUsers = filteredUsers.filter(
        (user) => user.agency["id"] === parseInt(agencyInput.value)
      );
    }

    if (statusInput.value && statusInput.value !== "") {
      filteredUsers = filteredUsers.filter(
        (user) => user.status === statusInput.value
      );
    }

    if (usernameInput.value && usernameInput.value !== "") {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.name.toLowerCase().includes(usernameInput.value.toLowerCase()) ||
          user.surname.toLowerCase().includes(usernameInput.value.toLowerCase())
      );
    }

    setFilteredUsers(filteredUsers);
  }

  const handleFilterClick = (selectedFilter) => {
    if (selectedFilter === filter) {
      setSort(sort === "asc" ? "desc" : "asc");
    } else {
      setFilter(selectedFilter);
      setSort("asc");
    }
  };

  const userSheet = [
    {
      name: "Liste des bénéficiaires",
      headers: [
        "Nom",
        "Prénom",
        "Ville",
        "Statut",
        "Sous statut",
        "En POEC ?",
        "En POEI ?",
        "Dans un groupe ?",
        "En RSA ?",
        "En FOREM ?",
        "Nom de l'agence",
        "Date début de contrat",
        "Date fin de contrat",
        "Durée de l'accompagnement date début - date fin",
        "Nombre d'actions effectuées",
        "Durée totale des actions",
        "Durée totale du parcours",
        "Durée réelle du parcours (entre les périodes de pause)",
        "Commentaire",
      ],
      data: filteredUsers.map((user) => ({
        Nom: user.name,
        Prénom: user.surname,
        Ville: user.address,
        Statut: user.status,
        "Sous statut":
          user.status === UserStatus.KOOSHII_END
            ? user.closeStatus
            : user.status === UserStatus.KOOSHII_ACTIVE
            ? user.actifStatus
            : user.status === UserStatus.KOOSHII_PAUSE
            ? user.pauseStatus
            : "",
        "En POEC ?": user.isPoec === true ? "Oui" : "Non",
        "En POEI ?": user.isPoei === true ? "Oui" : "Non",
        "Dans un groupe ?": user.isGroupe === true ? "Oui" : "Non",
        "En RSA ?": user.isRSA === true ? "Oui" : "Non",
        "En FOREM ?": user.isFOREM === true ? "Oui" : "Non",
        "Nom de l'agence": user.agency.name,
        "Date début de contrat": user.date_start
          ? new Date(user.date_start)
          : "",
        "Date fin de contrat": user.date_end ? new Date(user.date_end) : "",
        "Durée de l'accompagnement": "",
        "Nombre d'actions effectuées": user.totalMonitoring,
        "Durée totale des actions": user.totalMonitoringTime,
        "Durée totale du parcours": user.timeInsideThePath,
        "Durée réelle du parcours (entre les périodes de pause)":
          user.realTimeInsideThePath,
        Commentaire: user.comment,
      })),
    },
    {
      name: "Détail des suivis",
      data: filteredUsers.flatMap((user) =>
        user.monitorings.map((monitoring) => ({
          "Nom du candidat": user.name,
          "Prénom du candidat": user.surname,
          Agence: user.agency.name,
          "Nom de l'atelier": monitoring.actionName,
          "Date de l'atelier": moment(monitoring.date).format("DD/MM/YYYY"),
          "Durée de l'atelier (min)": monitoring.time,
          "Comentaire de l'atelier": monitoring.remark,
          "Permanent ayant effectué l'action":
            monitoring.employeeSurname + " " + monitoring.employeeName,
        }))
      ),
      headers: [
        "Nom du candidat",
        "Prénom du candidat",
        "Agence",
        "Nom de l'atelier",
        "Date de l'atelier",
        "Durée de l'atelier (min)",
        "Comentaire de l'atelier",
        "Permanent ayant effectué l'action",
      ],
    },
  ];

  return (
    <div
      style={{
        width: "100vw",
        height: "83vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading ? (
        <Spinner showFirstText={false} />
      ) : (
        <>
          <div
            style={{
              marginBottom: "2vh",
            }}
            id="filtre"
          >
            <select
              name="agency"
              id="agency"
              onChange={(event) => {
                filterUsers();
              }}
            >
              <option value="">Toutes agences</option>
              {agencies.map((agency) => (
                <option key={agency.id} value={agency.id}>
                  {agency.name}
                </option>
              ))}
            </select>
            <select
              name="status"
              id="status"
              onChange={(event) => {
                filterUsers();
              }}
            >
              <option value="">Tous les status</option>
              {Object.values(UserStatus).map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
            <input
              onChange={(event) => {
                filterUsers();
              }}
              type="search"
              name="username"
              id="username"
              placeholder="Ex : George Washington"
            />
            <div
              style={{
                border: "0.3rem solid #024873",
                borderRadius: "0.5rem",
                width: "10vw",
                marginTop: "1rem",
                marginLeft: "0.5rem",
              }}
            >
              <Excel fileName="extraction_user" sheets={userSheet}>
                Extraction Excel
              </Excel>
            </div>
          </div>
          <span style={{ marginBottom: "2vh" }}>
            Nombre total de bénéficiaire "Actif" : {totalActif}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1vh",
            }}
          >
            <a href="/user" style={buttonNew}>
              Nouveau bénéficiaire
            </a>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "99%",
                columnGap: "3vw",
                fontSize: "1.1vw",
                fontWeight: "500",

                background:
                  "radial-gradient(circle, #F2F2F2 30%, #F1E51166 100%)",
              }}
            >
              <span
                style={{
                  flexBasis: "13vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleFilterClick("name")}
              >
                Nom{" "}
                {filter === "name" && sort === "desc" && <span>&uarr;</span>}
                {filter === "name" && sort === "asc" && <span>&darr;</span>}
              </span>
              <span
                style={{
                  flexBasis: "13vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleFilterClick("surname")}
              >
                Prénom{" "}
                {filter === "surname" && sort === "desc" && <span>&uarr;</span>}
                {filter === "surname" && sort === "asc" && <span>&darr;</span>}
              </span>
              <span
                style={{
                  flexBasis: "13vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleFilterClick("status")}
              >
                Statut{" "}
                {filter === "status" && sort === "desc" && <span>&uarr;</span>}
                {filter === "status" && sort === "asc" && <span>&darr;</span>}
              </span>
              <span
                style={{
                  flexBasis: "13vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleFilterClick("agency")}
              >
                Agence{" "}
                {filter === "agency" && sort === "desc" && <span>&uarr;</span>}
                {filter === "agency" && sort === "asc" && <span>&darr;</span>}
              </span>
              <span
                style={{
                  flexBasis: "26.3vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Actions
              </span>
            </div>
          </div>
          <ScrollBar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                rowGap: "1.3vh",
                width: "100%",
              }}
            >
              {filteredUsers
                .sort((a, b) => {
                  let comparison = 0;
                  if (filter === "agency") {
                    if (a.agency && b.agency) {
                      comparison = a.agency.name.localeCompare(b.agency.name);
                    }
                  } else {
                    comparison = a[filter].localeCompare(b[filter]);
                  }
                  return sort === "asc" ? comparison : -comparison;
                })
                .map((user, index) => (
                  <div
                    key={user.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "99%",
                      flexWrap: "wrap",
                      ...(index % 2 === 0
                        ? {
                            background:
                              "radial-gradient(circle, #F2F2F2 30%, #8FAADC66 100%)",
                          }
                        : {
                            background:
                              "radial-gradient(circle, #F2F2F2 30%, #F1E51166 100%)",
                          }),
                      columnGap: "3vw",
                      fontSize: "1.1vw",
                      fontWeight: "500",
                    }}
                  >
                    <span
                      style={{
                        flexBasis: "13vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.name}
                    </span>

                    <span
                      style={{
                        flexBasis: "13vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.surname}
                    </span>

                    <span
                      style={{
                        flexBasis: "13vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.status}
                    </span>

                    <span
                      style={{
                        flexBasis: "13vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {user.agency.name}
                    </span>
                    <span
                      style={{
                        flexBasis: "18vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: "1vw",
                      }}
                    >
                      <a style={buttonNew} href={`/user/${user.id}`}>
                        Modifier
                      </a>
                      <a style={buttonNew} href={`/user/m/${user.id}`}>
                        Voir son suivi
                      </a>
                    </span>
                  </div>
                ))}
            </div>
          </ScrollBar>
        </>
      )}
    </div>
  );
}

export default UsersList;
